<template lang="pug">
Dropdown#reportAs(
  :label="$t('reportAs')"
  layout="col"
  :items="reportAsOptions"
  v-model="reportAsValue"
)
  template(#help)
    span {{ $t(`reportAsTooltip.${reportAsValue}`) }}
</template>
<script>
  import { mapState } from 'vuex';
  import Dropdown from '../Dropdown/Dropdown.vue';

  export default {
    components: {
      Dropdown,
    },
    props: {
      value: {
        type: [String, Number],
      },
    },
    computed: {
      ...mapState(['mobilePreview', 'isEmbedded']),
      reportAsOptions() {
        const options = [{ value: 'conversion', text: this.$t('conversion') }];

        if (!this.isEmbedded) {
          options.push({ value: 'rejected', text: this.$t('rejected') });
        }

        options.push({ value: 'none', text: this.$t('none') });
        return options;
      },
      reportAsValue: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        },
      },
    },
  };
</script>
