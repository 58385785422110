<template lang="pug">
Heading(label)
  template(#titleTrailing)
    .label {{ $t('buttonCampaignStatus') }}
  template(#prependEnding)
    om-switch#goalReached(v-model="goalReached")
  template(#help)
    span {{ goalReached ? $t('buttonCampaignStatusTooltip') : $t('buttonCampaignStatusTooltipOff') }}
</template>
<script>
  import Heading from '../Heading.vue';

  export default {
    components: {
      Heading,
    },
    props: {
      value: {
        type: Boolean,
      },
    },
    computed: {
      goalReached: {
        get() {
          return this.value;
        },
        set(v) {
          this.$emit('input', v);
        },
      },
    },
  };
</script>
