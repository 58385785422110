<template lang="pug">
Dropdown#align(
  :label="labelOverride || $t('align')"
  :layout="layout"
  :items="alignItems"
  v-model="alignValue"
  :editMobile="editMobile"
)
</template>
<script>
  import { mapState } from 'vuex';
  import Dropdown from '../../Dropdown/Dropdown.vue';

  export default {
    components: {
      Dropdown,
    },
    props: {
      value: {
        type: String,
      },
      editMobile: {
        type: Boolean,
        default: false,
      },
      labelOverride: {
        type: String,
      },
      layout: {
        type: String,
        default: 'row',
        options: ['row', 'col'],
        validator: (value) => {
          return ['row', 'col'].includes(value);
        },
      },
    },
    computed: {
      ...mapState(['mobilePreview']),
      alignItems() {
        const baseOptions = [
          { value: 'left', text: this.$t('left') },
          { value: 'center', text: this.$t('center') },
          { value: 'right', text: this.$t('right') },
        ];

        if (this.mobilePreview) {
          baseOptions.unshift({ value: null, text: this.$t('sameAsDesktop') });
        }

        return baseOptions;
      },
      alignValue: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        },
      },
    },
  };
</script>
