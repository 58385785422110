<template lang="pug">
Accordion(fix :fixOptions="fixOptions")
  template(v-if="!hideTitle" #title)
    span {{ labelWithDefault }}
  template(v-if="deviceSelector && !hideTitle" #device)
    DeviceSelector(:hasViewText="false")
  slot(name="beforeColor")
  slot(name="color")
    OmColorInput(
      :label="colorLabel || $t('background')"
      :typeOfComponent="typeOfComponent"
      layout="col"
      dsAllowed
    )
    slot(name="afterBackground")
  slot(name="border")
    Border(
      :typeOfComponent="typeOfComponent"
      :selectedProperty="borderSelectedProp"
      :typeProperty="borderTypeProp"
      :widthProperty="borderWidthProp"
      :coloringProperty="borderColorProp"
    )
      template(#afterColor)
        slot(name="borderAfterColor")
  template(#moreOrLess)
    slot(name="cornerRadius" v-if="$slots.cornerRadius")
    CornerRadius(v-if="!$slots.cornerRadius")
    slot(name="shadow" v-if="$slots.shadow || shadow")
      Shadow(
        v-model="shadowComputed"
        :editMobile="editMobile"
        :typeOfComponent="typeOfComponent"
        :coloringProperty="shadowColorProp"
      )
</template>

<script>
  import DeviceSelector from '@/editor/components/sidebar/components/DeviceSelector.vue';
  import Accordion from '@/components/Editor/Accordion/Accordion.vue';
  import Heading from '@/components/Editor/Heading.vue';
  import CornerRadius from '@/components/Editor/Controls/CornerRadius.vue';
  import Border from '@/components/Editor/Controls/Border.vue';
  import Shadow from '@/components/Editor/Controls/Shadow.vue';
  import itemMixin from '@/editor/mixins/item';

  export default {
    components: { Accordion, Heading, CornerRadius, Border, DeviceSelector, Shadow },
    mixins: [itemMixin],
    props: {
      typeOfComponent: {
        type: String,
        required: true,
      },
      deviceSelector: {
        type: Boolean,
        default: true,
      },
      label: {
        type: String,
      },
      shadow: {
        type: Boolean,
        default: false,
      },
      borderProp: {
        type: String,
        default: '$device.border',
      },
      shadowProp: {
        type: String,
        default: '$device.shadow',
      },
      hideTitle: {
        type: Boolean,
        default: false,
      },
      fixOptions: {
        type: Boolean,
        default: false,
      },
      colorLabel: {
        type: String,
      },
      editMobile: {
        type: Boolean,
        default: true,
      },
    },
    data: () => ({ canEditMobile: true }),
    computed: {
      shadowComputed: {
        get() {
          return this.getValueOf(this.shadowTypeProp);
        },
        set(value) {
          this.setValueOf(this.shadowTypeProp, value);
        },
      },
      labelWithDefault() {
        return this.label ?? this.$t('backgroundAndBorder');
      },
      borderSelectedProp() {
        return `${this.borderProp}.selectedBorder`;
      },
      borderTypeProp() {
        return `${this.borderProp}.type`;
      },
      borderWidthProp() {
        return `${this.borderProp}.width`;
      },
      borderColorProp() {
        return `${this.borderProp}.color`;
      },
      shadowTypeProp() {
        return `${this.shadowProp}.type`;
      },
      shadowColorProp() {
        return `${this.shadowProp}.color`;
      },
    },
  };
</script>
